import React from "react";
import '../../styles/components/breadcrumbs.scss';
import { Link } from "react-router-dom";

const BreadCrumb = ({ pages }) => {
    return (
        <ul className="breadCrumbs">
            {pages.map((page, index) => (
                <li key={index}>
                    {index !== pages.length - 1 ? (
                        <Link to={page[1]}>
                            {page[0]}
                        </Link>
                    ) : (
                        page[0]
                    )}
                </li>
            ))}
        </ul>
    );
};

export default BreadCrumb;
