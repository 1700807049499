import React from "react";
import Box from "../../components/layout/box";
import DevicesTable from "../../components/tables/devicestable";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="content--boxes">
                <Box type="standart">
                    <h1>{t("dashboard_title")}</h1>
                    <DevicesTable />
                </Box>
            </div>
        </>
    );
};

export default Dashboard;
