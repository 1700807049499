import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/layout/languageSwitcher.scss';
import { Icon } from '@iconify/react';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
  
    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
      setDropdownVisible(false);
    };
  
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropdownRef]);

  return (
    <div className="languageSwitcher" ref={dropdownRef}>
      <Icon
            className='languageSwitcher__switch topbar__icon'
            icon="material-symbols:language"
            onClick={() => setDropdownVisible(!dropdownVisible)}
          />
      <div className={`languageSwitcher__dropdown ${dropdownVisible ? 'show' : ''}`}>
        <a onClick={() => changeLanguage('en')} className={i18n.language === 'en' ? 'active' : ''}>English</a>
        <a onClick={() => changeLanguage('cs')} className={i18n.language === 'cs' ? 'active' : ''}>Česky</a>
      </div>
    </div>
  );
};

export default LanguageSwitcher;