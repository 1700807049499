import React from 'react'
import '../../styles/layout/boxes.scss'
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types';

const Box = ( { children, type, content, boxClass, boxWidth } ) => {
    if (type === 'number') {
        return (
            <div className='box box--number'>
                <Icon className='box__icon' icon={content.icon}/>
                <span className="box__title">{content.title}</span>
                <span className="box__number">{content.number}</span>
            </div>
          )
    }
    if (type === 'standart') {
        return (
            <div className={'box ' + boxClass + ' box-' + boxWidth}>
                { children }
            </div>
          )
    }
}

Box.propTypes = {
    type: PropTypes.oneOf(['standart', 'number']),
    content: {
        title: PropTypes.string,
        number: PropTypes.number,
        icon: PropTypes.string,
    }
}

export default Box
