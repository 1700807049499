import React, { useMemo, useState, useEffect } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    usePagination,
} from "react-table";
import { Icon } from "@iconify/react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../styles/components/tables.scss";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";

const DevicesTable = () => {
    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const { t, i18n } = useTranslation();
    const sessionToken = Cookies.get("sessionToken");

    useEffect(() => {
        const fetchAllDevices = async () => {
            try {
                const res = await axios.get(
                    "https://us-central1-crcyou-c9d92.cloudfunctions.net/app/devices"
                );
                const devicesArray = Object.values(res.data);
                setDevices(devicesArray);
                setIsLoading(false);
            } catch (err) {
                console.log(err);
                setIsLoading(false);
            }
        };
        fetchAllDevices();
    }, []);

    const exportToExcel = () => {
        const exportData = devices.map((device) => {
            const exportObject = {
                [t("device_title")]: device.mg08d_name,
                [t("table_deviceId")]: device.mg08d_id,
                [t("ev_number")]: device.mg08d_nr,
                [t("device_location")]: device.mg08d_loca,
                [t("table_rentedFrom")]: device.mg08d_rent_from,
                [t("table_rentedUntil")]: device.mg08d_rent_to
            };
            if (sessionToken !== "sanofi_user!") {
                exportObject[t("device_prize")] = device.mg08d_price;
            }
            return exportObject;
        });
    
        const ws = XLSX.utils.json_to_sheet(exportData);
        ws['!cols'] = [{ wch: 30 }, { wch: 20 }, { wch: 15 }, { wch: 30 }, { wch: 12 }, { wch: 12 }, { wch: 15 }];
        const priceColumn = 'G';
        const numRows = exportData.length;
        for (let i = 1; i <= numRows; i++) {
            const cellRef = priceColumn + (i + 1); // +1 protože Excel začíná od řádku 2 (1 je záhlaví)
            if (ws[cellRef]) {
                ws[cellRef].z = '#,##0.00 "CZK"'; // Nastavení formátu měny
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, t("devices"));
        XLSX.writeFile(wb, t("excel_name"));
    };

    const columns = useMemo(
        () => [
            {
                Header: t("table_device"),
                accessor: "device_name",
                className: "first-column",
                Cell: ({ row }) => (
                    <Link
                        to={`/zarizeni/${row.original.device_id}`}
                        className="device-link"
                    >
                        {row.original.device_name}
                    </Link>
                ),
            },
            {
                Header: t("table_deviceId"),
                accessor: "ev_number",
            },
            {
                Header: t("table_calibration"),
                accessor: "next_control",
            },
            {
                Header: t("table_rentedUntil"),
                accessor: "rented_to",
            },
        ],
        [t, i18n.language]
    );

    const data = useMemo(() => {
        return devices.map((device) => ({
            device_id: device.mg08d_id,
            device_name: device.mg08d_name,
            ev_number: device.mg08d_nr,
            rented_to: formatDate(device.mg08d_rent_to),
            next_control: device.mg08d_next_control,
        }));
    }, [devices]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        state: { pageIndex: tablePageIndex, pageSize: tablePageSize },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChangeRowsPerPage = (e) => {
        setPageSize(parseInt(e.target.value));
        setPageIndex(0);
    };

    const handleChangePage = (page) => {
        setPageIndex(page);
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    };

    const filteredData = useMemo(() => {
        if (!searchValue) return page;
        return page.filter((row) =>
            Object.values(row.values).some(
                (value) =>
                    value &&
                    value
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
            )
        );
    }, [page, searchValue]);

    const displayedData = useMemo(() => {
        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;
        return filteredData.slice(startIndex, endIndex);
    }, [filteredData, pageIndex, pageSize]);

    if (isLoading) {
        return <div>{t("loading")}...</div>;
    }

    return (
        <>
            <div className="table__header">
                <div className="table__search">
                    <input
                        value={searchValue}
                        onChange={handleSearch}
                        placeholder={t("table_search") + "..."}
                    />
                </div>
                <button className="table__export" onClick={exportToExcel}>
                    <Icon
                        icon="ph:export"
                        width="20"
                        height="20"
                    />
                    {t("export")}
                </button>
                {/* <div className="table__select-rows">
          <span>Počet řádků na stránku: </span>
          <select value={pageSize} onChange={handleChangeRowsPerPage}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div> */}
            </div>
            <div style={{ overflowX: "auto" }}>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        className={
                                            column.isSorted
                                                ? column.isSortedDesc
                                                    ? "sort-desc"
                                                    : "sort-asc"
                                                : ""
                                        }
                                    >
                                        {column.render("Header")}
                                        <Icon
                                            className="sort__icon"
                                            icon="material-symbols:arrow-back-ios-new"
                                            width="10"
                                            height="10"
                                            rotate={1}
                                        />
                                        <Icon
                                            className="sort__icon"
                                            icon="material-symbols:arrow-back-ios-new"
                                            width="10"
                                            height="10"
                                            rotate={3}
                                        />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {displayedData.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {/* <div className="table__pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <span>
          Stránka {pageIndex + 1} z {pageCount}
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
      </div> */}
        </>
    );
};

export default DevicesTable;
