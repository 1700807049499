import React from 'react'
import {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import Box from '../../components/layout/box'
import axios from 'axios'
import DevicesTable from '../../components/tables/devicestable';

const Devices = () => {
const userId = localStorage.getItem('userId');

  return (
    <>
    <div className='content--boxes'>
    <Box type={'standart'}>
      <h1>Přehled</h1>
      <DevicesTable/>
    </Box>
    </div>
    </>
  )
}

export default Devices
