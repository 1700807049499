import { useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import React from "react";
import "../../styles/layout/sidebar.scss";
import Logo from "../../images/brand/logo.svg";
import LogoShort from "../../images/brand/icon.png";
import { useTranslation } from 'react-i18next';

const SideBar = ({isVisible, isMobile, toggleSidebar}) => {
    const { t } = useTranslation();
    const { collapsed, collapseSidebar } = useProSidebar();
    const [activePage, setActivePage] = useState(null);

    function handleActive(event) {
        if (!event.target.classList.value.includes("active")) {
            event.target.classList.toggle("active");
            if (activePage) activePage.classList.remove("active");
            setActivePage(event.target);
        }
    }

    const displayStyle = isVisible  ? "flex" : "none";
    const positionStyle = !isMobile ? "static" : "absolute";
    const heightStyle = !isMobile ? "auto" : "100%";

    return (
        <>
            <div style={{ display: displayStyle, height: heightStyle, position: positionStyle, zIndex: 1000}}>
                <Sidebar className="sidebar">
                    <Menu>
                        <div className="sidebar__logoWrapper">
                            {collapsed && (
                                <img
                                    className="sidebar__logo sidebar__logo--short"
                                    src={LogoShort}
                                    alt=""
                                />
                            )}
                            {!collapsed && (
                                <img 
                                    className="sidebar__logo" 
                                    src={Logo} 
                                    alt=""
                                />
                            )}
                        </div>
                        <MenuItem
                            className="sidebar__item"
                            active={window.location.pathname === "/" || window.location.pathname.includes("/zarizeni/")}
                            onClick={handleActive}
                            component={<Link to="/" />}
                            icon={<Icon icon="bx:home-circle" />}
                        >
                            {t("devices")}
                        </MenuItem>
                    </Menu>

                    {/* Close Icon */}
                    {collapsed && (
                        <Icon
                            className="sidebar__closeIcon"
                            onClick={isMobile ?  toggleSidebar : () => collapseSidebar()}
                            icon="material-symbols:arrow-forward-ios-rounded"
                        />
                    )}
                    {!collapsed && (
                        <Icon
                            className="sidebar__closeIcon"
                            onClick={isMobile ?  toggleSidebar : () => collapseSidebar()}
                            icon="material-symbols:arrow-back-ios-new-rounded"
                        />
                    )}
                </Sidebar>
            </div>
        </>
    );
};

export default SideBar;
