import React from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate here
import '../../styles/layout/topbar.scss';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './languageSwitcher';

const TopBar = ({ isAuthenticated, onLogout, toggleSidebar }) => {
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  const handleLogout = () => {
    onLogout();
    navigate('/prihlaseni'); // Use navigate to navigate to the login page after logout
  };

  return (
    <>
      <div className="topbar">
        <Icon
            className="topbar__menutoggle"
            icon="ci:hamburger-md"
            onClick={toggleSidebar} // Use the handleLogout function
          />
        <div className="col--user">
            <LanguageSwitcher/>
            <Icon
                className="topbar__icon topbar__logout"
                icon="bi:box-arrow-right"
                onClick={handleLogout} // Use the handleLogout function
            />
            <Icon className="topbar__user" icon="mdi:user" />
        </div>
      </div>
    </>
  );
};

export default TopBar;