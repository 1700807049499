import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "../../components/layout/box";
import axios from "axios";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../components/layout/breadcrumb";
import "../../styles/components/lists.scss";
import "../../styles/components/badges.scss";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const DevicePage = () => {
    const { t } = useTranslation();
    const { deviceId } = useParams();
    const [device, setDevice] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    useEffect(() => {
        const fetchDevice = async () => {
            try {
                const res = await axios.get(
                    `https://us-central1-crcyou-c9d92.cloudfunctions.net/app/devices/${deviceId}`
                );
                setDevice(res.data);
                console.log(res.data)
                setLoading(false); // Nastaví načítání na "false" po úspěšném načtení dat
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchDevice();
    }, [deviceId]);

    const sessionToken = Cookies.get("sessionToken");

    let remainingMonths = 0;
    let percentageElapsed = 0;

    if (device) {
        const today = new Date();
        const startDate = new Date(device.mg08d_rent_from);
        const endDate = new Date(device.mg08d_rent_to);

        const totalMonths =
            (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            endDate.getMonth() -
            startDate.getMonth();
        const elapsedMonths =
            (today.getFullYear() - startDate.getFullYear()) * 12 +
            today.getMonth() -
            startDate.getMonth();
        remainingMonths =
            (endDate.getFullYear() - today.getFullYear()) * 12 +
            endDate.getMonth() -
            today.getMonth();

        percentageElapsed = (elapsedMonths / totalMonths) * 100;
    }
    function MothsInflection(months) {
        if (months === 1) return t("month_remaining_1");
        if (months >= 2 && months <= 4) return t("remain_2") + " " + months + " " + t("months_2");
        if (months <= 0) return t("rental_ended");
        return t("remain_1") + " " + months + " " + t("months_1");
    }

    return (
        <>
            <BreadCrumb
                pages={[
                    [t("device"), "/zarizeni"],
                    [device?.mg08d_name, `null`],
                ]}
            />
            <div className="content--boxes">
                {loading ? (
                    <div>{t("loading")}...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : device ? (
                    <>
                        <Box type={"standart"} boxClass="minimal" boxWidth="2">
                            <span className="title">{t("device_title")}</span>
                            <span className="stat">{device.mg08d_name}</span>
                        </Box>
                        <Box type={"standart"} boxClass="minimal">
                            <span className="title">{t("ev_number")}</span>
                            <span className="stat">{device.mg08d_nr}</span>
                        </Box>
                        <Box type={"standart"} boxClass="minimal">
                            <span className="title">{t("prod_number")}</span>
                            <span className="stat">
                                {device.mg08d_serial_nr}
                            </span>
                        </Box>
                        <Box type={"standart"} boxClass="minimal" boxWidth="2">
                            <span className="title">{t("contract_info")}</span>
                            <ul className="stats">
                                <li>
                                    <span className="stats__title">
                                        {t("contract_number")}
                                    </span>
                                    <span className="stats__value">{device.mg08d_customer_order_nr}</span>
                                </li>
                                <li>
                                    <span className="stats__title">
                                        {t("project_number")}
                                    </span>
                                    <span className="stats__value">{device.mg08d_customer_project_nr}</span>
                                </li>
                                <li>
                                    <span className="stats__title">
                                        {t("device_location")}
                                    </span>
                                    <span className="stats__value">{device.mg08d_loca}</span>
                                </li>
                            </ul>
                        </Box>
                        <Box
                            type={"standart"}
                            boxClass="minimal rentingDates"
                            boxWidth="2"
                        >
                            <div className="row">
                                <span className="title">{t("rental_time")}</span>
                                <span
                                    class={`badge ${
                                        remainingMonths > 3
                                            ? "badge-success"
                                            : "badge-warning"
                                    }`}
                                >
                                    {MothsInflection(remainingMonths)}
                                </span>
                            </div>
                            <div className="row mt-2">
                                <span className="date">
                                    {formatDate(device.mg08d_rent_from)}
                                </span>
                                <span className="date">
                                    {formatDate(device.mg08d_rent_to)}
                                </span>
                            </div>
                            <progress value={percentageElapsed} max="100">
                                {" "}32%{" "}
                            </progress>
                        </Box>
                        {sessionToken !== "sanofi_user!" ? (
                            <Box type={"standart"} boxClass="minimal">
                                <span className="title">{t("device_prize")}</span>
                                <span className="stat">
                                    {device.mg08d_price} Kč <small>/&nbsp;{t("monthly")}</small>
                                </span>
                            </Box>
                        ) : null}
                    </>
                ) : null}
            </div>
        </>
    );
};

export default DevicePage;
