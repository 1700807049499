import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../../styles/components/login.scss';
import Logo from '../../images/brand/logo.svg';
import TopIllustration from '../../images/login/top-illustration.svg';
import BottomIllustration from '../../images/login/bottom-illustration.svg';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/layout/languageSwitcher';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLogin = async () => {
        if (username === '' || password === '') {
            setErrorMessage(t("please_fill_all"));
            return;
        }

        const requestData = {
            username: username,
            password: password,
        };

        fetch('https://us-central1-crcyou-c9d92.cloudfunctions.net/app/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Response:', data);

                if (data.message === 'nalogovan') {
                    Cookies.set('sessionToken', data.user_id, { expires: 1 });
                    onLogin(data.user_id);
                    navigate('/');
                };
                if (data.error === 'nouser') {
                    setErrorMessage(t("user_not_found"));
                    return;
                } if (data.error === 'wrongpassword') {
                    setErrorMessage('Zadali jste špatné heslo');
                    return;
                }
                 else {
                    console.error('Login failed.');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="login">
            <div className="login__content">
                <img className="login__topIllustration" src={TopIllustration} alt="" />
                <div className="login__box">
                    <img className="logo" src={Logo} alt="" />
                    <h3>{t('welcome')}</h3>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <div className="form">
                        <label htmlFor="username">{t("username")}</label>
                        <input
                            type="text"
                            placeholder={t("your_username")}
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <label htmlFor="password">{t("password")}</label>
                        <input
                            type="password"
                            placeholder={t("your_password")}
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button className="button" onClick={handleLogin}>
                            {t("login")}
                        </button>
                        
                    </div>
                </div>
                <div className='login__languageSwitcher'>
                    <LanguageSwitcher />
                </div>
                <img className="login__bottomIllustration" src={BottomIllustration} alt="" />
            </div>
        </div>
    );
};

export default Login;



/*
//////////////////
sanofi_admin
+?92XN9Wa
//////////////////
sanofi_user
1lZO-1EA
/////////////////
developer
3E2-0Ns1
/////////////////*/