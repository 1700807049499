import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './scenes/dashboard';
import Devices from './scenes/devices';
import SideBar from './components/layout/sidebar';
import TopBar from './components/layout/topbar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import './styles/layout/layout.scss';
import ThemeProvider from './components/context/themeProvider';
import DevicePage from './scenes/devices/device';
import Login from './scenes/login';
import './styles/core/helpers.scss';
import Cookies from 'js-cookie';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  // detekce mobilního zařízení
  const [isSidebarVisible, setSidebarVisibility] = useState(!isMobile);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is stored in cookies as authenticated
    const sessionToken = Cookies.get('sessionToken');
    const isAuthenticatedInLocalStorage = localStorage.getItem('isAuthenticated');
    if (sessionToken || isAuthenticatedInLocalStorage === 'true') {
      setIsAuthenticated(true);
      console.log(sessionToken);
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to set authentication state after successful login
  const handleLogin = (userId) => {
    setIsAuthenticated(true);
    Cookies.set('sessionToken', userId, { expires: 1 }); // Token expiration set to 1 day
    localStorage.setItem('isAuthenticated', 'true');
    navigate('/');
  };

  // Function to set authentication state after logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    Cookies.remove('sessionToken'); // Remove user ID from cookies
    localStorage.removeItem('isAuthenticated');
    navigate('/prihlaseni');
  };

  // If initialization is not completed yet, show loading
  if (!isInitialized) {
    return <div>Loading...</div>;
  }
 
  return (
    <ThemeProvider>
      <div className="app">
        <ProSidebarProvider>
          {/* Conditionally display Sidebar and TopBar only for authenticated users */}
          {isAuthenticated ? <SideBar isMobile={isMobile} isVisible={isSidebarVisible} isAuthenticated={isAuthenticated} onLogout={handleLogout} toggleSidebar={() => setSidebarVisibility(!isSidebarVisible)} /> : null}
          <main className="content">
            {isAuthenticated ? <TopBar isMobile={isMobile} isAuthenticated={isAuthenticated} toggleSidebar={() => setSidebarVisibility(!isSidebarVisible)} onLogout={handleLogout} /> : null}
            <Routes>
              <Route path="/prihlaseni" element={<Login onLogin={handleLogin} />} />
              {/* Private routes for authenticated users */}
              {isAuthenticated ? (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/zarizeni" element={<Devices />} />
                  <Route path="/zarizeni/:deviceId" element={<DevicePage />} />
                </>
              ) : (
                // If the user is not authenticated, redirect to the login page
                <Route path="/*" element={<Navigate to="/prihlaseni" />} />
              )}
            </Routes>
          </main>
        </ProSidebarProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
